<!-- <vex-page-layout>
  <vex-page-layout-header *ngIf="this.mode === 'menu'" class="h-24 flex flex-col items-start justify-center">
    <div [class.container]="layoutCtrl.value === 'boxed'" [class.px-gutter]="layoutCtrl.value === 'fullwidth'"
      class="w-full flex flex-col sm:flex-row justify-between">
      <div>
        <h1 class="title mt-2 mb-1">Expediente de venta</h1>
        <vex-breadcrumbs [crumbs]="['Apps', 'Sales Table']"></vex-breadcrumbs>
      </div>

      <div class="hidden sm:block">
        <mat-button-toggle-group [formControl]="layoutCtrl" class="mt-2 sm:mt-0">
          <mat-button-toggle value="boxed">Reducir</mat-button-toggle>
          <mat-button-toggle value="fullwidth">Ampliar</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  </vex-page-layout-header>
  <vex-page-layout-content [class.container]="layoutCtrl.value === 'boxed'"
    [class.px-gutter]="layoutCtrl.value === 'fullwidth'" class="-mt-6"> -->

    <form (ngSubmit)="save()" [formGroup]="form">
      <div *ngIf="this.mode != 'menu'" class="flex items-center" mat-dialog-title cdkDrag
        cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>

        <h2 *ngIf="form.get('name').value" class="headline m-0 flex-auto">{{
          form.get('name').value}}</h2>
        <h2 *ngIf="!form.get('name').value" class="headline m-0 flex-auto">Nuevo expediente de
          venta
        </h2>

        <!--  <button [matMenuTriggerFor]="settingsMenu" class="text-secondary" mat-icon-button type="button">
          <mat-icon svgIcon="mat:more_vert"></mat-icon>
        </button>-->
        <button class="text-secondary" (click)="close()" mat-icon-button type="button">
          <mat-icon svgIcon="mat:close"></mat-icon>
        </button>
      </div>
      <!--   <mat-divider class="-mx-6 text-border"></mat-divider> -->

      <mat-tab-group class="vex-tabs vex-tabs-dense border-0" (selectedTabChange)="onTabChange($event)">
        <mat-tab label="GENERAL">
          <ng-template mat-tab-label>
            <mat-icon class="mr-2" matPrefix svgIcon="mat:info"></mat-icon>
            GENERAL
          </ng-template>
          <mat-dialog-content class="flex flex-col">

            <div class="flex flex-col sm:flex-row">
              <mat-form-field class="flex-auto">
                <mat-label>Expediente</mat-label>
                <input formControlName="name" matInput>
              </mat-form-field>
              <mat-form-field class="sm:ml-6 flex-auto">
                <mat-label>Tipo de transporte</mat-label>
                <mat-select [compareWith]="compareObjects" cdkFocusInitial [disabled]="readOnly"
                  (selectionChange)="changePurchaseType($event.value)" formControlName="shipmentCostType"
                  placeholder="Seleccionar tipo de transporte">
                  <mat-option *ngFor="let item of shipmentTypes" [value]="item">
                    {{item.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="sm:ml-6 flex-auto">
                <mat-label>Tipo de expediente</mat-label>
                <mat-select [compareWith]="compareObjects" formControlName="administrativeFileType" [disabled]="readOnly"
                  placeholder="Seleccionar tipo de expediente">
                  <mat-option *ngFor="let item of administrativeFileTypes" [value]="item">
                    {{item.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="flex flex-col sm:flex-row">
              <mat-form-field *ngIf="linkedAdminFiles && linkedAdminFiles[0]" >
                <mat-label>Expedientes de compra</mat-label>
                <mat-chip-list aria-label="Fish selection" class="flex flex-col sm:flex-row max-w-1">
                  <mat-chip 
                    class="leading-none flex items-center justify-center hover:bg-hover text-primary bg-primary/10 cursor-pointer min-w-8" (click)="loadAdminFile(element?.id)"
                    *ngFor="let element of linkedAdminFiles">{{element?.administrativeFileReference}}</mat-chip>
                </mat-chip-list>
              </mat-form-field>
              <mat-form-field  *ngIf="linkedAdminFiles && linkedAdminFiles[0]" class="sm:ml-6 flex-auto">
                <mat-label>Clientes</mat-label>
                <mat-select [compareWith]="compareObjects"  [disabled]="readOnly"
                  formControlName="entity" placeholder="Seleccionar cliente">
                  <mat-option *ngFor="let item of clients" [value]="item">
                    {{item.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field  *ngIf="!linkedAdminFiles || !linkedAdminFiles[0]" class="flex-auto">
                <mat-label>Clientes</mat-label>
                <mat-select [compareWith]="compareObjects" [disabled]="readOnly"
                  formControlName="entity" placeholder="Seleccionar cliente">
                  <mat-option *ngFor="let item of clients" [value]="item">
                    {{item.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
                <mat-checkbox formControlName="externalShipment" [disabled]="readOnly">Transporte gestionado por entidad externa
                </mat-checkbox>
            </div>
            <mat-form-field class="flex-auto">
              <mat-label>Notas</mat-label>
              <textarea formControlName="remarks" matInput [readonly]="readOnly"></textarea>
            </mat-form-field>
          </mat-dialog-content>
        </mat-tab>
        <mat-tab label="VEHÍCULOS"  [disabled]='isCreateMode()'>
          <ng-template mat-tab-label>
            <mat-icon class="mr-2" matPrefix svgIcon="mat:directions_car"></mat-icon>
            VEHÍCULOS
          </ng-template>
          <ng-template matTabContent>
            <vex-vehicles [(filters)]="filters" [payChargeTitle]="payChargeTitle" [(vehicleSaleFilters)]="vehicleSaleFilters"
              [type]="type"></vex-vehicles>
          </ng-template>
        </mat-tab>

        <mat-tab label="PROFORMAS"  [disabled]='isCreateMode()'>
          <ng-template mat-tab-label>
            <mat-icon class="mr-2" matPrefix svgIcon="mat:line_style"></mat-icon>
            PROFORMAS
          </ng-template>
          <ng-template matTabContent>
            <vex-invoice-table [(filters)]="invoiceFilters" [type]="type" [invoiceTitle]="invoiceTitle" [payChargeTitle]="payChargeTitle"
              [isMenu]="false"></vex-invoice-table>
          </ng-template>
        </mat-tab>
        <mat-tab label="FACTURAS"  [disabled]='isCreateMode()'>
          <ng-template mat-tab-label>
            <mat-icon class="mr-2" matPrefix svgIcon="mat:line_style"></mat-icon>
            FACTURAS
          </ng-template>
          <ng-template matTabContent>
            <vex-invoice [(filters)]="invoicesFilters" [isMenu]="false"  [(readOnly)]="readOnly" [isExpedient]="true"></vex-invoice>
          </ng-template>
        </mat-tab>
        <mat-tab label="COBROS" [disabled]='isCreateMode()'>
          <ng-template mat-tab-label>
            <mat-icon class="mr-2" matPrefix svgIcon="mat:money"></mat-icon>
            COBROS
          </ng-template>
          <ng-template matTabContent>
            <vex-pay-charge-table [isMenu]="false" [type]="proformaType"  [(readOnly)]="readOnly" [payChargeTitle]="payChargeTitle"
              [(filters)]="invoiceFilters"></vex-pay-charge-table>
          </ng-template>
        </mat-tab>
        <mat-tab label="COSTES" [disabled]='isCreateMode()'>
          <ng-template mat-tab-label>
            <mat-icon class="mr-2" matPrefix svgIcon="mat:money"></mat-icon>
            COSTES
          </ng-template>
          <ng-template matTabContent>
            <costs [(filters)]="invoiceFilters" [isMenu]=false  [(linkService)]="linkService" [amountSett]="amountSett"  (updatedSettledAmount)="getIsUpdatedValue($event)" [(readOnly)]="readOnly"></costs>
          </ng-template>
        </mat-tab>
        <mat-tab label="DOCUMENTOS" [disabled]='isCreateMode()'>
          <ng-template mat-tab-label>
            <mat-icon class="mr-2" matPrefix svgIcon="mat:attach_file"></mat-icon>
            DOCUMENTOS
          </ng-template>
          <ng-template matTabContent>
            <vex-documents [isMenu]="false" [editabled]="!readOnly" [(documentType)]="documentType" [(reference)]="reference"></vex-documents>
          </ng-template>
        </mat-tab>
      </mat-tab-group>

      <div *ngIf="form.get('documentsRemarks')?.enabled" class="flex flex-col sm:flex-row max-w-[95%] m-auto">
        <mat-form-field class="flex-auto">
          <mat-label>Notas de documentos</mat-label>
          <textarea formControlName="documentsRemarks" matInput></textarea>
        </mat-form-field>
      </div>

      <mat-dialog-actions align="end" class="float-right">
        <button *ngIf="this.mode != 'menu'" (click)="close()" mat-flat-button type="button" class="m-1">
          <mat-icon class="mr-2" svgIcon="mat:cancel"></mat-icon>
          <span>Cancelar</span>
        </button>
        <!-- <button *ngIf="!isDocManager" color="primary" mat-flat-button type="button" [disabled]="!form.valid" class="m-1">
          <mat-icon class="mr-2" svgIcon="mat:close"></mat-icon>
          <span>Cerrar</span>
        </button> -->
        <button *ngIf="!readOnly" color="primary" mat-flat-button confirm-dialog type="button" [disabled]="!form.valid" class="m-1" (click)="showDeleteAdministrativeFileAlert()">
          <mat-icon class="mr-2" svgIcon="mat:delete"></mat-icon>
          <span>Eliminar expediente</span>
        </button>
        <button *ngIf="!readOnly" color="primary" mat-flat-button type="submit" [disabled]="!form.valid || disabled" class="m-1">
          <mat-icon class="mr-2" svgIcon="mat:save"></mat-icon>
          <span>Grabar</span>
        </button>
      </mat-dialog-actions>
    </form>
<!--   </vex-page-layout-content>
</vex-page-layout> -->


<!-- <mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
  <button mat-menu-item>
    <mat-icon svgIcon="mat:print"></mat-icon>
    <span>Imprimir</span>
  </button>

  <button mat-menu-item>
    <mat-icon svgIcon="mat:download"></mat-icon>
    <span>Exportar</span>
  </button>

    <button mat-menu-item>
    <mat-icon svgIcon="mat:delete"></mat-icon>
    <span>Eliminar</span>
  </button>
</mat-menu> -->