<form (ngSubmit)="save()" [formGroup]="form"  *ngIf="form">
  <div class="flex items-center" mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>

    <h2 *ngIf="form?.get('name')?.value" class="headline m-0 flex-auto">{{ form?.get('name')?.value }}</h2>
    <h2 *ngIf="!form?.get('name')?.value" class="headline m-0 flex-auto">Nuevo Documento
    </h2>

   <!--  <button [matMenuTriggerFor]="settingsMenu" class="text-secondary" mat-icon-button type="button">
      <mat-icon svgIcon="mat:more_vert"></mat-icon>
    </button> -->

    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>
  <!-- 
  <mat-divider class="-mx-6 text-border"></mat-divider>
 -->
  <mat-dialog-content class="flex flex-col">
    <div class="flex flex-col sm:flex-row">
      <mat-form-field class="flex-auto">
        <mat-label>Nombre del documento</mat-label>
        <input cdkFocusInitial formControlName="name" matInput required>
      </mat-form-field>
      <mat-form-field *ngIf="!documentCategoryId" documentCategoryId class="sm:ml-6 flex-auto">
        <mat-label>Categoría documental</mat-label>
        <mat-select formControlName="documentCategory" [compareWith]="compareCategoryObjects" (selectionChange)="showEstimatedDate($event.value)"
          placeholder="Seleccionar categoría" required>
          <mat-option *ngFor="let item of categories" [value]="item">
            {{item.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="form.get('estimatedDate')?.enabled" class="sm:ml-6 flex-auto">
        <mat-label>Fecha de previsión</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="estimatedDate" placeholder="Seleccionar fecha" required>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="flex flex-col sm:flex-row">
      <mat-form-field *ngIf="!reference" class="flex-auto">
        <mat-label>Tipo</mat-label>
        <mat-select #type formControlName="documentType" [compareWith]="compareCategoryObjects"
          placeholder="Seleccionar tipo" (valueChange)="changeType($event)" [required]="requiredType">
          <mat-option *ngFor="let item of docTypes" [value]="item">
            {{item.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field cdkScrollable *ngIf="checkType()" class="sm:ml-6 flex-auto">

        <!--  <mat-icon [*ngif]="documentType === 'Expediente'" (click)="loadCars($event)" svgIcon="mat:car"></mat-icon> -->
        <mat-label appearance="fill">
          {{(form.get('documentType').value).name}} </mat-label>
        <!--   <div class="float-left"> -->
        <input class="border-0 outline-none w-full bg-transparent" type="text" [dynamicWidth]="{ minWidth: 50, maxWidth: 200 }" (valueChange)="setExpedient($event)"
          [required]="requiredReference" placeholder="Seleccionar {{type?.value?.name}}" matInput
           aria-label="Number" [matAutocomplete]="autoModel"
          formControlName="referenceName"
          (input)="SearchWeightuggestions($event.target.value)">
        <!--     [(ngModel)]="filters.referenceId" (ngModelChange)="pageIndex=0;loadData();" >  -->
        <!--<mat-autocomplete #autoModel="matAutocomplete" [displayWith]="displayFn" [(ngModel)]="filters.referenceId" (ngModelChange)="pageIndex=0;loadData();"  (optionsScroll)="onScroll()"
          placeholder="Seleccionar {{type.name}}" class="px-4 py-2 border-0 outline-none w-full bg-transparent"> -->
        <mat-autocomplete (optionSelected)="setExpedient()" showPanel="true" (optionsScroll)="onScroll()" 
        #autoModel="matAutocomplete" panelWidth="auto" showPanel="true" [displayWith]="displayFn"
          class="mat-select-panel flex-auto">
          <mat-option *ngFor="let item of filteredOptions | async" [value]="item.name">
            {{item.name}} 
          </mat-option> 
        </mat-autocomplete>
      </mat-form-field>
    </div>


   <!--  <mat-accordion class="mb-5 width-full" *ngIf="isUpdateMode()">
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title class="text-center block">
            Modificar archivo
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="flex flex-col sm:flex-row width-full" >
        <mat-form-field fxFlex class="flex-auto">
          <mat-label>Archivo</mat-label>
          <ngx-mat-file-input matInput formControlName="file" type="file" (change)="handleFileInput($event)">
          </ngx-mat-file-input>
          <mat-icon svgIcon="mat:folder" matSuffix></mat-icon>

        </mat-form-field>
        </div>
      </mat-expansion-panel>
    </mat-accordion> -->
    <div class="flex flex-col sm:flex-row width-full" >
      <mat-form-field fxFlex class="flex-auto">
        <mat-label>Archivo</mat-label>
        <div class="progress" [style.width]="progress + '%'">
        </div>
        <div class="container" appDnd (fileDropped)="onFileDropped($event)" draggable=true>
          <input requiered hidden=true formControlName="files" matInput />
          <input requiered type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)" />
<!--           <ngx-mat-file-input requiered autofilled="false" matInput multiple #fileDropRef id="fileDropRef" formControlName="files" type="file" (change)="fileBrowseHandler($event.target.files)">
          </ngx-mat-file-input> -->
          <svg xmlns="http://www.w3.org/2000/svg" class="display-inline" width="63" height="64" viewBox="0 0 63 64">
            <g fill="#3B454F" fill-rule="nonzero">
              <path
                d="M42.656 15.135a1.953 1.953 0 0 1-1.391-.578L31.5 4.795l-9.765 9.762a1.97 1.97 0 1 1-2.785-2.785L30.106.616a1.97 1.97 0 0 1 2.785 0l11.157 11.156a1.97 1.97 0 0 1-1.392 3.363z" />
              <path
                d="M31.5 36.791a1.97 1.97 0 0 1-1.969-1.969V2.01a1.97 1.97 0 0 1 3.938 0v32.812a1.97 1.97 0 0 1-1.969 1.969z" />
              <path
                d="M55.781 63.041H7.22A7.225 7.225 0 0 1 0 55.822V41.385a4.599 4.599 0 0 1 4.594-4.594h7.234a4.567 4.567 0 0 1 4.402 3.276l2.814 9.382a.658.658 0 0 0 .628.467h23.656a.658.658 0 0 0 .628-.467l2.814-9.385a4.572 4.572 0 0 1 4.402-3.273h7.234A4.599 4.599 0 0 1 63 41.385v14.437a7.225 7.225 0 0 1-7.219 7.219zM4.594 40.729a.656.656 0 0 0-.657.656v14.437a3.286 3.286 0 0 0 3.282 3.282H55.78a3.286 3.286 0 0 0 3.282-3.282V41.385a.656.656 0 0 0-.657-.656h-7.234a.65.65 0 0 0-.628.467L47.73 50.58a4.628 4.628 0 0 1-4.402 3.274H19.672a4.567 4.567 0 0 1-4.402-3.276l-2.814-9.382a.65.65 0 0 0-.628-.467H4.594z" />
            </g>
          </svg>
        
          <h3>Arrastrar archivo aquí</h3>
          <!-- <h3>o</h3>
          <label for="fileDropRef">Navege en su explorador</label> -->
        </div>
        <div class="files-list">
          <div class="single-file" *ngFor="let file of files; let i = index">
            <div class="file-icon" style="width: 50px">
              <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px" y="0px" viewBox="0 0 58 58" style="enable-background:new 0 0 58 58;" xml:space="preserve">
                <polygon style="fill:#EDEADA;" points="51.5,14 37.5,0 6.5,0 6.5,58 51.5,58 " />
                <g>
                  <path style="fill:#CEC9AE;"
                    d="M16.5,23h25c0.552,0,1-0.447,1-1s-0.448-1-1-1h-25c-0.552,0-1,0.447-1,1S15.948,23,16.5,23z" />
                  <path style="fill:#CEC9AE;"
                    d="M16.5,15h10c0.552,0,1-0.447,1-1s-0.448-1-1-1h-10c-0.552,0-1,0.447-1,1S15.948,15,16.5,15z" />
                  <path style="fill:#CEC9AE;"
                    d="M41.5,29h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,29,41.5,29z" />
                  <path style="fill:#CEC9AE;"
                    d="M41.5,37h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,37,41.5,37z" />
                  <path style="fill:#CEC9AE;"
                    d="M41.5,45h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,45,41.5,45z" />
                </g>
                <polygon style="fill:#CEC9AE;" points="37.5,0 37.5,14 51.5,14 " />
              </svg>
            </div>
            <div class="info">
              <h4 class="name">
                {{ file?.name }}
              </h4>
              <p class="size">
                {{ formatBytes(file?.size) }}
              </p>
              <app-progress *ngIf="progress" [progress]="file?.progress"></app-progress>
            </div>
        
            <div class="delete" (click)="deleteFile(i);$event.stopPropagation()">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18">
                <path fill="#B1B1B1" fill-rule="nonzero"
                  d="M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v10zm3.17-7.83a.996.996 0 0 1 1.41 0L7 9.59l1.42-1.42a.996.996 0 1 1 1.41 1.41L8.41 11l1.42 1.42a.996.996 0 1 1-1.41 1.41L7 12.41l-1.42 1.42a.996.996 0 1 1-1.41-1.41L5.59 11 4.17 9.58a.996.996 0 0 1 0-1.41zM10.5 1L9.79.29C9.61.11 9.35 0 9.09 0H4.91c-.26 0-.52.11-.7.29L3.5 1H1c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1h-2.5z" />
              </svg>
            </div>
            
            <!--<div>
              <button type="button" (click)="openFile(file);$event.stopPropagation()">Abrir archivo</button>
            </div>-->
          </div>
        </div>
      <!--   <ngx-mat-file-input required formControlName="file" type="file" (change)="handleFileInput($event)">
        </ngx-mat-file-input> -->
      <!--   <mat-icon svgIcon="mat:folder" matSuffix></mat-icon> -->
      <mat-error *ngIf="form.get('files').hasError('required')"><strong>Archivo obligatorio</strong>
      </mat-error>

      </mat-form-field>

    </div>
    <div class="flex flex-col sm:flex-row">
      <mat-form-field class="flex-auto">
        <mat-label>Descripción</mat-label>
        <textarea formControlName="description" matInput></textarea>
      </mat-form-field>
    </div>

    <!-- <div *ngIf="this.documentType == 'Expediente'" class="flex flex-col sm:flex-row"> -->
      <div *ngIf="params" class="flex flex-col sm:flex-row">
      <vex-table [params]="params">
      </vex-table>
    </div>

  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">Cancelar</button>
    <button *ngIf="isCreateMode()" color="primary" mat-flat-button type="submit" [disabled]="!form.valid">Nuevo
      Documento</button>
    <button *ngIf="isUpdateMode()" color="primary" mat-flat-button type="submit" [disabled]="!form.valid">Actualizar
      Documento</button>
  </mat-dialog-actions>
</form>

<!-- <mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
  <button mat-menu-item>
    <mat-icon svgIcon="mat:print"></mat-icon>
    <span>Imprimir</span>
  </button>

  <button mat-menu-item>
    <mat-icon svgIcon="mat:download"></mat-icon>
    <span>Exportar</span>
  </button>

  <button mat-menu-item>
    <mat-icon svgIcon="mat:delete"></mat-icon>
    <span>Eliminar</span>
  </button>
</mat-menu> -->