<vex-page-layout>


    <vex-page-layout-header mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle
        *ngIf="this.params.showAmount"
        class="flex flex-col items-start">
        <div [class.container]="layoutCtrl.value === 'boxed'" [class.px-gutter]="layoutCtrl.value === 'fullwidth'"
            class="w-full flex flex-col sm:flex-row justify-between">
            <div>
                <h1 class="title mt-2 mb-1">Importe a liquidar: {{this.total}}</h1>
                <h1 class="title mt-2 mb-1">Total: {{this.settleText}}</h1>
                <h1 class="title mt-2 mb-1" [ngStyle]="{'color':getColorAmount()}">Pendiente liquidar final:
                    {{this.pendingText}}</h1>
            </div>


        </div>
    </vex-page-layout-header>
    <vex-page-layout-header mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle
        *ngIf="this.params.title === 'Proformas' && this.params.isMenu"
        class="h-32 flex flex-col items-start justify-center">
        <mat-select class="pb-20" cdkFocusInitial [(value)]="params.extraValue" (valueChange)="sendDataToParent($event)"
            placeholder="Seleccionar tipo">
            <!-- <mat-option value=""></mat-option> -->
            <mat-option value="1">Cobro</mat-option>
            <mat-option value="2">Pago</mat-option>
        </mat-select>

    </vex-page-layout-header>
    <!-- <vex-page-layout-header mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle
        *ngIf="this.params.title != 'Movimientos bancarios' && this.params.title != 'Proformas'  && this.params.title != 'Pagos'  && this.params.title != 'Cobros'"
        class="h-24 flex flex-col items-start justify-center">

    </vex-page-layout-header> -->
    <vex-page-layout-header mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle
        *ngIf="!this.params.showAmount"
        class="h-24 flex flex-col items-start justify-center">
    </vex-page-layout-header>
    <!-- <vex-page-layout-header *ngIf="this.params.title === 'Proformas' && !this.params.isMenu"
        class="h-24 flex flex-col items-start justify-center">
    </vex-page-layout-header> -->
    <vex-page-layout-content [class.container]="layoutCtrl.value === 'boxed'"
        [class.px-gutter]="layoutCtrl.value === 'fullwidth'" class="-mt-6">

        <div class="card -mt-16">
            <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
                <h2
                    class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none">
                    <span *ngIf="params.uniqueSelect && selection.isEmpty()">{{this.params.element}}</span>
                    <span *ngIf="selection.hasValue()">{{ selection.selected.length }}
                        <span *ngIf="selection.selected.length === 1">{{this.params.element.toLowerCase()}}</span>
                        <span *ngIf="selection.selected.length > 1">{{this.params.title.toLowerCase()}}</span>
                        seleccionado<span *ngIf="selection.selected.length > 1">s</span></span>
                </h2>

                <div *ngIf="selection.hasValue()" class="mr-4 pr-4 border-r flex-none">
                    <button (click)="addItems(selection.selected)" color="primary" mat-icon-button
                        matTooltip="Añadir {{this.params.title.toLowerCase()}} seleccionados" type="button">
                        <mat-icon svgIcon="mat:add_circle"></mat-icon>
                    </button>

                </div>

                <div *ngIf="!this.params.paginatedOnBack"
                    class="bg-foreground rounded-full border px-4 max-w-[300px] flex-auto flex items-center border border-gray-300">
                    <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
                    <input [formControl]="searchCtrl" class="px-4 py-2 border-0 outline-none w-full bg-transparent"
                        placeholder="Buscar..." type="search">
                </div>
                <div *ngIf="this.params.paginatedOnBack"
                    class="bg-foreground rounded-full border px-4 max-w-[300px] flex-auto flex items-center border border-gray-300">
                    <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
                    <input [(ngModel)]="params.filters.name" (ngModelChange)="pageIndex=0;loadData();"
                        class="px-4 py-2 border-0 outline-none w-full bg-transparent" placeholder="Buscar..."
                        type="search">
                </div>

                <span class="flex-1"></span>

                <!--  <button [matMenuTriggerFor]="settingsMenu" class="text-secondary" mat-icon-button type="button">
                    <mat-icon svgIcon="mat:more_vert"></mat-icon>
                </button> -->


                <button *ngIf="params.isMenu" (click)="createItem()" class=" flex-none" color="primary" mat-mini-fab
                    matTooltip="Añadir {{this.params.element}}" type="button">
                    <mat-icon svgIcon="mat:add"></mat-icon>
                </button>


                <button (click)="close()" class="ml-4 text-secondary" mat-dialog-close mat-icon-button type="button">
                    <mat-icon svgIcon="mat:close"></mat-icon>
                </button>

            </div>
            <div class="overflow-auto max-h-[30rem]">
                <table @stagger id="top" [dataSource]="dataSource" class="w-full" mat-table matSort matTableFilter 
                    matSortDirection="desc" (matSortChange)="sortData($event)">

                    <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

                    <!-- Checkbox Column -->
                    <ng-container matColumnDef="checkbox">
                        <th *matHeaderCellDef mat-header-cell>
                            <mat-checkbox *ngIf="!params.uniqueSelect && visible"
                                (change)="$event ? masterToggle() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()" color="primary">
                            </mat-checkbox>
                        </th>
                        <td *matCellDef="let row" class="w-4" mat-cell>
                            <mat-checkbox *ngIf="getVisible(row) && checkTransport(row)"
                                (change)="$event ? selectFile(row) : null" (click)="$event.stopPropagation()"
                                [checked]="selection.isSelected(row)" color="primary">
                            </mat-checkbox>
                        </td>
                    </ng-container>

                    <!-- Image Column -->
                    <ng-container matColumnDef="image">
                        <th *matHeaderCellDef mat-header-cell></th>
                        <td *matCellDef="let row" class="w-8 min-w-8 pr-0" mat-cell>
                            <img [src]="row['imageSrc']" class="avatar h-8 w-8 align-middle">
                        </td>
                    </ng-container>

                    <!-- Text Columns -->
                    <ng-container *ngFor="let column of params.columns; trackBy: trackByProperty">
                        <ng-container *ngIf="column.type === 'text' || column.type === 'textarea'"
                            [matColumnDef]="column.property">
                            <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label
                                }}</th>
                            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ row[column.property]
                                }}</td>
                        </ng-container>
                        <ng-container *ngIf="column.type === 'select'" [matColumnDef]="column.property">
                            <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label
                                }}</th>
                            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{
                                row[column.property]?.name }}</td>
                        </ng-container>
                        <ng-container *ngIf="column.type === 'idSelect'" [matColumnDef]="column.property">
                            <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label
                                }}</th>
                            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{
                                row[column.property]?.id }}</td>
                        </ng-container>
                        <!--  <ng-container *ngIf="column.type === 'nested'" [matColumnDef]="column.property">
                                <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label
                                    }}</th>
                                <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{
                                    row[column.property]?.name }}</td>
                            </ng-container> -->
                        <ng-container *ngIf="column.type === 'expedient'" [matColumnDef]="column.property">
                            <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}
                            </th>
                            <td mat-cell *matCellDef="let list">
                                <mat-chip-list >
                                    <span *ngFor="let row of list[column.property] ">
                                    <mat-chip *ngIf="row && row?.orderType?.id === 1" class="text-green bg-green-light">
                                      {{ row?.name }} (
                                      <span *ngFor="let element of row.proformas; let itemIndex=index">{{ element?.name }} <span
                                          *ngIf="itemIndex < row.proformas.length -1">&nbsp;</span></span>
                                      )
                                    </mat-chip>
                                    <mat-chip *ngIf="row && row?.orderType?.id != 1" class="text-red bg-red-light">
                                      {{ row?.name }} (
                                      <span *ngFor="let element of row.proformas; let itemIndex=index">{{ element?.name }}<span
                                          *ngIf="itemIndex < row.proformas.length -1">&nbsp;</span></span>
                                      )
                                    </mat-chip>
                                    </span>
                                  </mat-chip-list>
                            </td>
                        </ng-container>
                        <ng-container *ngIf="column.type === 'nested'" [matColumnDef]="column.property">
                            <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}
                            </th>
                            <td mat-cell *matCellDef="let list">
                                <div *ngIf="list.proformas?.length > 0">
                                    <mat-chip-list *ngFor="let row of list.proformas">
                                        <mat-chip *ngIf="row" class="text-green bg-green-light">
                                            {{ list?.name }} - {{ row?.name }}
                                        </mat-chip>
                                        <mat-chip *ngIf="!row" class="text-green bg-green-light">
                                            {{ list?.name }}
                                        </mat-chip>
                                        <!-- <mat-chip *ngIf="row?.type === 'Cobro'"  class="text-green bg-green-light">
                                  {{ list?.name }} - {{ row?.name }}
                                </mat-chip>
                                <mat-chip *ngIf="row?.type === 'Pago'" class="text-red bg-red-light">
                                  {{ list?.name }} - {{ row?.name }}
                                </mat-chip> -->
                                    </mat-chip-list>
                                </div>
                                <div *ngIf="list.proformas?.length == 0">
                                    <mat-chip class="text-green bg-green-light">
                                        {{ list?.name }}
                                    </mat-chip>
                                </div>
                            </td>
                        </ng-container>
                        <ng-container *ngIf="column.type === 'date'" [matColumnDef]="column.property">
                            <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label
                                }}</th>
                            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ row[column.property]
                                |
                                date:'dd/MM/yyyy' }}</td>
                        </ng-container>
                        <ng-container *ngIf="column.type === 'number'" [matColumnDef]="column.property">
                            <th *matHeaderCellDef class="text-right uppercase" mat-header-cell> {{ column.label
                                }}</th>
                            <td class="text-right" *matCellDef="let row"
                                [ngStyle]="{'color':getColor(row[column.property])}" mat-cell>
                                {{ row[column.property] || 0 | number:'1.0-2':'es'}} €</td>
                        </ng-container>
                        <ng-container *ngIf="column.type === 'hour'" [matColumnDef]="column.property">
                            <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label
                                }}</th>
                            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ row[column.property]
                                |
                                date:'hh:mm:ss' }}</td>
                        </ng-container>
                        <ng-container *ngIf="column.type === 'chip'" [matColumnDef]="column.property">
                            <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label
                                }}</th>
                            <td mat-cell *matCellDef="let row of ">
                                <mat-chip-list>
                                    <span *ngFor="let element of row[column.property] ">
                                        <mat-chip *ngIf="element"
                                            class="leading-none flex items-center justify-center hover:bg-hover text-primary bg-primary/10">
                                            <mat-chip-avatar *ngIf="column.property === 'tenants'"><img
                                                    class="avatar-image"
                                                    src="{{'assets/img/flags/' + element + '.jpg'}}" />
                                            </mat-chip-avatar>{{element}}
                                        </mat-chip>
                                    </span>
                                </mat-chip-list>
                            </td>
                            <!--  <td mat-cell *matCellDef="let row">
                <mat-chip-list>
                  <mat-chip
                  class="leading-none flex items-center justify-center hover:bg-hover text-primary bg-primary/10">
                    <mat-chip-avatar><img class="avatar-image" src="assets/img/flags/spain.jpg" />
                    </mat-chip-avatar>España
                  </mat-chip>
                  <mat-chip
                  class="leading-none flex items-center justify-center hover:bg-hover text-primary bg-primary/10">
                    <mat-chip-avatar><img class="avatar-image" src="assets/img/flags/bulgary.jpg" />
                    </mat-chip-avatar>Bulgaria
                  </mat-chip>
                </mat-chip-list>
              </td> -->
                        </ng-container>
                        <ng-container *ngIf="column.type === 'flag'" [matColumnDef]="column.property">
                            <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label
                                }}</th>
                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox [(ngModel)]="row[column.property]"
                                    class="w-8 h-8 leading-none flex items-center justify-center hover:bg-hover"
                                    [class.mat-checkbox-disabled]="false" disabled>
                                </mat-checkbox>
                            </td>
                        </ng-container>
                    </ng-container>

                    <!-- Contact Column -->
                    <ng-container matColumnDef="element">
                        <th *matHeaderCellDef mat-header-cell mat-sort-header></th>
                        <td *matCellDef="let row" mat-cell>
                            <div class="flex">
                                <a (click)="$event.stopPropagation()"
                                    class="w-8 h-8 leading-none flex items-center justify-center hover:bg-hover text-primary bg-primary/10"
                                    mat-icon-button>
                                    <mat-icon class="icon-sm" svgIcon="mat:name"></mat-icon>
                                </a>

                                <a (click)="$event.stopPropagation()"
                                    class="w-8 h-8 leading-none flex items-center justify-center ml-1 hover:bg-hover text-teal bg-teal-light"
                                    mat-icon-button>
                                    <mat-icon class="icon-sm" svgIcon="mat:email"></mat-icon>
                                </a>

                                <a (click)="$event.stopPropagation()"
                                    class="w-8 h-8 leading-none flex items-center justify-center ml-1 hover:bg-hover text-green bg-green-light"
                                    mat-icon-button>
                                    <mat-icon class="icon-sm" svgIcon="mat:role"></mat-icon>
                                </a>
                            </div>
                        </td>
                    </ng-container>


                    <!-- Action Column -->
                    <!--  <ng-container matColumnDef="actions">
            <th *matHeaderCellDef mat-header-cell mat-sort-header></th>
            <td *matCellDef="let row" class="w-10 text-secondary" mat-cell>
              <button (click)="$event.stopPropagation()" [matMenuTriggerData]="{ element: row }"
                [matMenuTriggerFor]="actionsMenu" mat-icon-button type="button">
                <mat-icon svgIcon="mat:more_horiz"></mat-icon>
              </button>
            </td>
          </ng-container> -->

                    <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
                    <div *ngIf="params.disabledMode">
                        <tr *matRowDef="let row; columns: visibleColumns;" @fadeInUp mat-row></tr>
                    </div>
                    <div *ngIf="!params.disabledMode">
                        <tr (click)="selectFile(row)" *matRowDef="let row; columns: visibleColumns;" @fadeInUp
                            class="hover:bg-hover trans-ease-out cursor-pointer" mat-row></tr>
                    </div>
                </table>
            </div>
            <mat-paginator (page)="onPaginateChange($event)" showFirstLastButtons [pageSizeOptions]="pageSizeOptions"
                [pageSize]="pageSize" class="sticky left-0">
            </mat-paginator>

        </div>

    </vex-page-layout-content>

</vex-page-layout>



<mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
    <div (mouseleave)="closeMenu(columnFilterMenu)">
        <button (click)="toggleColumnVisibility(column, $event)" *ngFor="let column of params.columns"
            class="checkbox-item mat-menu-item">
            <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="column.visible" color="primary">
                {{ column.label }}
            </mat-checkbox>
        </button>
        <button style="float:right;" mat-button (click)="closeMenu(columnFilterMenu)">Aceptar</button>
    </div>
</mat-menu>





<!-- <mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
    <button mat-menu-item>
        <mat-icon svgIcon="mat:print"></mat-icon>
        <span>Imprimir</span>
    </button>

    <button mat-menu-item>
        <mat-icon svgIcon="mat:download"></mat-icon>
        <span>Exportar</span>
    </button>

</mat-menu> -->