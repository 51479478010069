import { Component, Inject, OnInit, isDevMode } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatColumnDef } from '@angular/material/table';
import { Router } from '@angular/router';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { IFilters } from 'src/app/interfaces/ifilters';
import { IModel} from 'src/app/interfaces/model';
import { ComboItem } from 'src/app/models/combo-item';
import { CommonItemList } from 'src/app/models/common-item';
import { KeyValue } from 'src/app/models/key-value';
import { CommonService } from 'src/app/services/api/common.service';
import { IService } from 'src/app/services/api/iService';
import { MasterService } from 'src/app/services/api/masters.service';
import { ToastService } from 'src/app/services/toast/toast.service';


@Component({
  selector: 'vex-create-update',
  templateUrl: './create-update.component.html',
  styleUrls: ['./create-update.component.scss']
})
export class CreateUpdateComponent<T extends IModel>   implements OnInit {

  
  console = console;
  panelOpenState = false;
  item: T;

  form: FormGroup;
  mode: 'create' | 'update' = 'create';
  disabled = false;
  ShowFilter = false;
  limitSelection = false;

  languages: Array<ComboItem> = [
    {
       id: 1,
       name: "DE",
       value: "",
     },
     {
       id: 2,
       name: "EN",
       value: "",
     },
     {
       id: 3,
       name: "ES",
       value: "",
     },
     {
       id: 4,
       name: "FR",
       value: "",
     },
     {
       id: 5,
       name: "IT",
       value: "",
     }];

  service: IService<T>;

  controls: Array<TableColumn<T>>;

  constructor(@Inject(MAT_DIALOG_DATA) protected defaults: any,
    private dialogRef: MatDialogRef<CreateUpdateComponent<T>>,
    private router: Router,
    private fb: FormBuilder,
    private masterService: MasterService<T>,
    private commonService: CommonService<T, IFilters>,
    private alert: ToastService) {

  }

  ngOnInit() {

    const group: any = {};
    this.controls = this.defaults.fields;
    if (this.defaults.values) {
      this.mode = 'update';
      group["id"] = new FormControl(this.defaults.values['id'] || '');
    } else {
      this.defaults.values = {} as T;

    }

    if(this.defaults.filters)
      this.service = this.commonService;
    else
      this.service = this.masterService;
    
    for (var control of this.controls) {
      if (control.type === 'nested' || control.type === 'accordion') {
        if(this.defaults.values[control.property])
          group[control.property] = new FormArray(this.createSubForm(this.defaults.values[control.property]));
        else
          group[control.property] = new FormArray(this.createSubForm(control.defaultValue));
        /*  for (var [index, item] of (this.defaults.values[control.property].entries())
           group['value' + index] = new FormControl(item.value || ''); */
      }
      else if (control.type === 'languages') {
        let lanGroup={}; 
        const values = this.defaults.values[control.property] || control.defaultValue;
        Object.getOwnPropertyNames(values).forEach(lan => {
          lanGroup[lan] = new FormControl(values[lan]);
       });
      ///lanGroup['es'].value = new FormControl(this.defaults.values['name']);

        group[control.property]  = new FormGroup(lanGroup);
      }
      else if (control.type === 'date') {
        group[control.property] = new FormControl(this.defaults.values[control.property] == undefined ? control.defaultValue == undefined ? null : new Date(control.defaultValue.toString()) : new Date(this.defaults.values[control.property]), control.validations);
      }
      else if (control.type === 'hour') {
        group[control.property] = new FormControl(((new Date(this.defaults.values[control.compareProperty])).getHours().toString() + ':' + (new Date(this.defaults.values[control.compareProperty])).getMinutes().toString().padStart(2, "0")) || control.defaultValue, control.validations);
      }
      else
      {
        //if(control.addable)
          group[control.property] = new FormControl(this.defaults.values[control.property] || control.defaultValue, control.validations);
      }
    };
    this.form = this.fb.group(group);

  }

  compareCategoryObjects(object1: any, object2: any) {
    return object1 && object2 && object1.id == object2.id;
  }
  createSubForm(array): Array<FormGroup> {
    const group: any = {};
    const formArray: Array<FormGroup> = [];
    let formGroup: FormGroup;
    /* for (var [index, control] of array.entries() ) {
      group['name' + index] = new FormControl(control.name || '');
      group['value' + index] = new FormControl(control.value || '');
    } */
    for (var control of array) {
      let formGroup: FormGroup = new FormGroup(
        {
          name: new FormControl(control.name),
          value: new FormControl(control.value)
        }
      );
      /*       group['name'] = new FormControl(control.name || '');
            group['value'] = new FormControl(control.value || '');
            formGroup= new FormGroup(group); */
      formArray.push(formGroup);
    }
    //let formGroup: FormGroup = new FormGroup(group);
    return formArray;
  }

/*   createLanSubForm(array, controls): Array<FormGroup> {
    const group: any = {};
    const formArray: Array<FormGroup> = [];
    let formGroup: FormGroup;

    for (var value of array) {
      let formGroup: FormGroup = new FormGroup(
        {
          name: new FormControl(control.name),
          value: new FormControl(control.value)
        }
      );
      formArray.push(formGroup);
    }

    return formArray;
  } */


  save() {
    if (this.mode === 'create') {
        this.createItem();
    } else if (this.mode === 'update') {
        this.updateItem();
    }
  }
  mapDataToModelArrayFunction<T>(cls: new (data: any) => T, array: any[]): T[] {
    const listToReturn: T[] = [];
    if (Array.isArray(array)) {
        array.forEach(item => {
            listToReturn.push(new cls(item));
        });
    }
    return listToReturn;
}
  createItem() {
    
    this.item = this.form.value;
    if(this.item?.translation)
      this.item.translation.es = this.item.name;
    this.item.id = null;
    this.item.isActive = true;
    if(this.defaults.filters){
      this.item.entity = new KeyValue(this.defaults.filters);
      if(this.defaults?.filters?.locationType) 
        this.item.locationType = this.defaults?.filters?.locationType;
    }
      
    let service: string = this.defaults.service;
    if(this.defaults?.linkService)
    {
      service = this.defaults.linkService;
      this.item.name == this.item.location;
      let items: Array<T>= [];
      items.push(this.item);

      let item: CommonItemList<T> = new CommonItemList<T>(this.item.entity.id, items);

      this.service.addRange(service, item).subscribe({
        next:
          response => {
            if (!response) {
              this.alert.error("No se ha podido crear el " + this.defaults.element);
            }
            else if (!response.isSuccessful) {
              this.alert.info(response.message);
            }
            else {
              this.item.id = response.data[0].id;
              this.dialogRef.close(response.data);
            }
          },
        error:
          (e) => {
            if(e.status == '401'){
              this.router.navigate(['/login']);
              this.alert.info("Sesión expirada")
              return;
            }
            if (isDevMode)
              this.alert.error(e.message)
            else
              this.alert.error("No se ha podido crear el " + this.defaults.element);
          }
      }) ;
    }
    else{
      this.service.create(service, this.item).subscribe({
        next:
          response => {
            if (!response) {
              this.alert.error("No se ha podido crear el " + this.defaults.element);
            }
            else if (!response.isSuccessful) {
              this.alert.info(response.message);
            }
            else {
              this.item.id = response.data[0]?.id;
              this.item.languages = this.form.value.languages;
              this.dialogRef.close(this.item);
            }
          },
        error:
          (e) => {
            if(e.status == '401'){
              this.router.navigate(['/login']);
              this.alert.info("Sesión expirada")
              return;
            }
            if (isDevMode)
              this.alert.error(e.message)
            else
              this.alert.error("No se ha podido crear el " + this.defaults.element);
          }
      }) ;
    }
   
    
    
  }

  updateItem() {
    const item = this.form.value;
    item.id = this.defaults.values.id;
    if(this.item?.translation)
      this.item.translation.es = this.item.name;
    item.languages = this.form.get('languages') as FormArray;
    if(this.defaults.filters)
      item.entity = new KeyValue(this.defaults.filters);

    this.service.update(this.defaults.service, item).subscribe({
      next:
        response => {
          if (!response) {
            this.alert.error("No se ha podido crear el " + this.defaults.element);
          }
          else if (!(response).isSuccessful) {
            this.alert.info(response.message);
          }
          else {
            //item.id = (await response).data[0].id;
            this.dialogRef.close(item);
          }
        },
      error:
        (e) => {
            if(e.status == '401'){
              this.router.navigate(['/login']);
              this.alert.info("Sesión expirada")
              return;
            }
          if (isDevMode)
            this.alert.error(e.message)
          else
            this.alert.error("No se ha podido crear el " + this.defaults.element);
        }
    }) ;

   
  }

  isCreateMode() {
    return this.mode === 'create';
  }

  isUpdateMode() {
    return this.mode === 'update';
  }

  /* onItemSelect(item: any) {
    console.log('onItemSelect', item);
  }
  onSelectAll(items: any) {
    console.log('onSelectAll', items);
  }
  */

  selectedRole(event: MatSelectChange) {
    this.defaults.value.role = event.source.triggerValue;
  }


  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  items(name): FormArray {
    return this.form.get(name) as FormArray;
  }

  value(itemIndex: number, name): FormArray {
    return this.items(name)
      .at(itemIndex)
      .get('value') as FormArray;
  }
   name(itemIndex: number, name): FormArray {
     return this.items(name)
       .at(itemIndex)
       .get('name') as FormArray;
   }
}
