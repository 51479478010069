import { Component, HostListener, Inject, Injectable, isDevMode, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Invoice } from '../../../../../models/pays-charges/invoice';
import { Vehicle } from '../../../../../models/vehicles/vehicle';
import { Note } from 'src/app/models/equipments/note';
import { Sale } from '../../../../../models/sales/sale';
import { Purchase } from 'src/app/models/expedients/purchase';
import { Transaction } from 'src/app/models/pays-charges/transaction';
import { CanDeactivate, Router } from '@angular/router';
import { CanComponentDeactivate } from 'src/app/services/utils/form-guard.service';
import { Observable } from 'rxjs';
import { IModel } from 'src/app/interfaces/model';
import { Expedient } from 'src/app/models/expedients/expedient';
import { ExpedientFilters } from 'src/app/models/expedients/expedient-filters';
import { CommonService } from 'src/app/services/api/common.service';
import { MasterService } from 'src/app/services/api/masters.service';
import { ToastService } from 'src/app/services/toast/toast.service';

import { CommonFilter } from 'src/app/models/masters/common-filter';
import { EmptyKeyValue, KeyValue } from 'src/app/models/key-value';
import { Entity } from 'src/app/models/contacts/entity';
import { StorageService } from 'src/app/services/utils/storage.service';
import { InvoiceFilters } from 'src/app/models/invoices/invoice-filters';
import { VehicleSaleFilters } from 'src/app/models/vehicles/vehicle-sale-filters';
import { LinkedAdminFiles } from 'src/app/models/expedients/linked-admin-files';
import { TransferData } from 'src/app/models/contacts/transfer-data';
import { SaleCreateUpdateComponent } from '../../sales/sale-create-update/sale-create-update.component';
import { PayChargeFilters } from 'src/app/models/pays-charges/pay-charge-filters';
import { ConfirmDialogComponent } from 'src/app/pages/apps/common-dialogs/confirm-dialog/confirm-dialog.component';
import { IFilters } from 'src/app/interfaces/ifilters';
import { MatTabChangeEvent } from '@angular/material/tabs';


@Component({
  selector: 'vex-purchase-create-update',
  templateUrl: './purchase-create-update.component.html',
  styleUrls: ['./purchase-create-update.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable({ providedIn: 'root' })
export class PurchaseCreateUpdateComponent implements OnInit, CanDeactivate<CanComponentDeactivate> {
  hasUnsavedData() {
    return this.form.dirty;
  }
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.hasUnsavedData()) {
      $event.returnValue = true;
    }
  }

  readOnly: boolean = false;

  layoutCtrl = new UntypedFormControl('fullwidth');
  service: string = 'administrative-files';
  filters: CommonFilter = new CommonFilter(null);
  invoiceFilters: InvoiceFilters = new InvoiceFilters();
  payChargeFilters: PayChargeFilters = new PayChargeFilters();
  vehicleSaleFilters: VehicleSaleFilters = new VehicleSaleFilters();
  offerFilters: ExpedientFilters;
  isUpdating = false;
  documentType: string = "Expediente";
  reference: KeyValue = new EmptyKeyValue();
  saleType: string = 'Oferta de venta';
  saleFilters: ExpedientFilters = new ExpedientFilters();
  linkService: string = "accountingentries/costs/administrativefiles";

  purchaseType: string;

  documentsTableData: Array<Document> = [];
  notesTableData: Array<Note> = [];
  invoicesTableData: Array<Invoice> = [];
  vehiclesTableData: Array<Vehicle> = [];
  transactionsTableData: Array<Transaction> = [];
  price: number;

  payChargeTitle = "Pago";
  invoiceTitle = "Proforma";
  type: KeyValue;
  proformaType: KeyValue;
  salesTableData: Array<Sale> = [];
  officeId: number;

  shipmentTypes: Array<KeyValue>;
  administrativeFileTypes: Array<KeyValue>;
  providers: Array<KeyValue>;
  linkedAdminFiles: Array<LinkedAdminFiles> = [];


  form: FormGroup;
  mode: 'create' | 'update' | 'menu' = 'create';

  amountSett: number = 0;

  vehicleTableData: Array<Vehicle>;

  disabled: boolean = false;


  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<PurchaseCreateUpdateComponent>,
    private router: Router,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private masterService: MasterService<IModel>,
    private masterServiceNoCache: CommonService<IModel, IFilters>,
    private alert: ToastService,
    private commonService: CommonService<Expedient, ExpedientFilters>,
    private storageService: StorageService) {
  }

  // Para controlar los modales
  currentTabLabel: string = 'GENERAL';

  getIsUpdatedValue(value) {
    //this.amountSett = value;

    if (Number(value) == Number(this.defaults?.values?.amount)) {
      this.defaults.values.amountSett = 0;
      this.amountSett = Number(this.defaults?.values?.amount);
    }
    else {
      this.defaults.values.amountSett = Number(this.defaults.values.amount) - Number(value);
      this.amountSett = Number(value);
    }


  }


  canDeactivate(): Observable<boolean> | boolean {

    if (!this.isUpdating && this.form.dirty) {
      this.isUpdating = false;
      this.dialog
        .open(ConfirmDialogComponent, {
          data: `¿Quiere salir y perder los cambios?`
        })
        .afterClosed()
        .subscribe((confirmado: Boolean) => {
          if (confirmado) {
            return false;
          }
        });
      //return this.dialogService.confirm('¿Quiere salir y perder los cambios?');
    }
    return true;
  }

  ngOnInit() {
    if (!this.defaults.type) {
      this.defaults.values = {} as Purchase;
      this.mode = 'menu';
    }
    else if (this.defaults?.values) {
      this.mode = 'update';
    } else {
      this.defaults.values = {} as Purchase;
    }

    this.amountSett = (Number(this.defaults?.values?.purchasePrice) < 0 ? Number(this.defaults?.values?.purchasePrice || 0) * -1 : Number(this.defaults?.values?.purchasePrice || 0)) - Number(this.defaults?.values?.amountPay || 0);

    const appUser = JSON.parse(localStorage.getItem("currentUser"));
    this.readOnly =appUser.roles.filter(y => y.id == 7 || y.id == 2 && (y.id != 1 || y.id != 4)).length > 0;

    this.proformaType = new EmptyKeyValue();
    this.proformaType.id = 1;
    this.proformaType.name = 'Venta';
    this.type = new EmptyKeyValue();
    this.type.id = 2;
    this.type.name = 'Compra';
    this.storageService.getItem("origin").subscribe(origin => {
      this.officeId = origin;

    })

    this.price = this.defaults.values.priceBuy;
    if (this.defaults?.filters)
      this.filters = this.defaults?.filters;
    this.filters.id = this.defaults.values.id;
    this.invoiceFilters.administrativeFileId = this.defaults.values.id;
    this.invoiceFilters.accountingEntryId = null;


    this.payChargeFilters.administrativeFileId = this.defaults.values.id;

    this.vehicleSaleFilters.entityId = this.defaults?.values?.entity?.id;
    this.vehicleSaleFilters.hasShipment = false;
    this.vehicleSaleFilters.orderTypeId = 2;

    //this.saleExpedients = this.defaults.values.saleExpedients;

    this.vehiclesTableData = this.defaults.values.vehicles || [];
    this.linkedAdminFiles = this.defaults?.values?.linkedAdminFiles;


    this.form = this.fb.group({
      id: [{ value: this.defaults.values.id, disabled: true }],
      name: [{ value: this.defaults.values.name, disabled: true }],
      shipmentCostType: [this.defaults.values.shipmentCostType, Validators.required],
      purchasePrice: this.defaults.values.purchasePrice,
      administrativeFileType: [this.defaults.values.administrativeFileType, Validators.required],
      entity: [this.defaults.values.entity, Validators.required],
      documentsRemarks: this.defaults.values.documentsRemarks || '',
      remarks: this.defaults.values.remarks || '',
      externalShipment: null
    });

    this.form.get('documentsRemarks')?.disable();

    this.getItems();

    const keyValue = new EmptyKeyValue;
    keyValue.id = this.defaults.values.id || null;
    keyValue.name = this.defaults.values.name || "";
    this.reference = keyValue;

  }
  async getItems() {

    this.shipmentTypes = (await this.masterService.get("administrative-files/shipment-types")).map(x => new KeyValue(x));
    this.administrativeFileTypes = (await this.masterService.get("administrative-files/admin-file-types")).map(x => new KeyValue(x));
    const entities = await this.masterServiceNoCache.getAllItems("entities") as unknown as Array<Entity>;
    //const entities = await this.masterService.getAll("entities") as unknown as Array<Entity>;
    this.providers = entities.filter(x => x.entityType?.id == 2).map(x => new KeyValue(x));

  }
  /* transformAdminFiles(adminFiles){
    let result: Array<string> = []; 
    adminFiles.foreach(x=> {
      result.push(x.administrativeFileReference)
    })
  } */
  loadFilters(id) {
    this.saleFilters.id = id;
    this.saleFilters.pageNumber = 1;
    this.saleFilters.rowsPerPage = 1;
    this.saleFilters.order = 'asc';
    this.saleFilters.orderBy = '';
    this.storageService.getItem("origin").subscribe(origin => {
      if (this.saleFilters.officeId != origin) {
        this.saleFilters.officeId = origin;
      }
    })
    this.filters.orderTypeId = 1;

  }
  loadAdminFile(id) {
    this.loadFilters(id);
    this.commonService.getAll(this.service, this.saleFilters).subscribe({
      next:
        response => {
          if (!response) {
            this.alert.error("Expediente no encontrado");
          }
          else if (!response.isSuccessful) {
            this.alert.info(response.message);
          }
          else {
            const sales = response.data.map(x => new Purchase(x));
            sales[0].linkedAdminFiles = null;
            this.dialog.open(SaleCreateUpdateComponent, {
              data: new TransferData(
                {
                  values: sales[0],
                  type: this.saleType,
                  filters: this.saleFilters
                })
            });
          }
        },
      error:
        (e) => {
          if (e.status == '401') {
            this.router.navigate(['/login']);
            this.alert.info("Sesión expirada")
            return;
          }
          if (isDevMode)
            this.alert.error(e.message)
          else
            this.alert.error("No ha sido posible recuperar el expediente");
        }
    });

    /*  var response = await this.commonService.getAll(this.service, this.filters);
       
     this.contacts = response.data;
     this.paginator.length = response.total;
     this.subsribeData() */
  }
  /*   goAdminFile(id){
  
      this.dialog.open(SaleCreateUpdateComponent, {
        data: new TransferData(
        {
          values: sale,
          type: this.saleType,
          filters: this.filters
        })
      });
    } */

  save() {
    const purchase = this.form.value;
    purchase.isSale = false;

    purchase.administrativeFileType = this.form.value.administrativeFileType || new EmptyKeyValue();

    purchase.entity = this.form.value.entity || new EmptyKeyValue();

    purchase.office = new EmptyKeyValue();

    purchase.office.id = this.officeId;
    if (this.mode === 'create') {
      this.createPurchase(purchase);
    } else if (this.mode === 'update') {
      this.updatePurchase(purchase);
    } else if (this.mode === 'menu') {
      this.createPurchase(purchase);
    }
  }

  createPurchase(purchase) {

    this.addPurchase(purchase);
  }

  updatePurchase(purchase) {

    if ((purchase?.shipmentCostType?.id == 1 && this.defaults?.values?.shipmentCostType?.id == 2) || (purchase?.shipmentCostType?.id == 1 && this.defaults?.values?.shipmentCostType?.id == 3)) {
      this.dialog
        .open(ConfirmDialogComponent, {
          data: `Se eliminarán los costes de transporte. ¿Quiere continuar?`
        })
        .afterClosed()
        .subscribe((confirmado: Boolean) => {
          if (confirmado) {
            purchase.id = this.defaults.values.id;

            this.modifyPurchase(purchase);
          }
        });
    }
    else {
      purchase.id = this.defaults.values.id;

      this.modifyPurchase(purchase);
    }

  }

  addPurchase(purchase: Expedient) {
    this.disabled = true;
    this.commonService.create(this.service, purchase).subscribe(
      {
        next:
          response => {
            if (!response) {
              this.alert.error("No ha sido posible dar de alta el expediente");
            }
            else if (!response.isSuccessful) {
              this.alert.info(response.message);
              //this.ngOnInit();
            }
            else {
              purchase.id = response.data[0].id;
              this.defaults.values.id = purchase.id;
              this.form.value.name = response.data[0].name;
              this.form.controls['name'].patchValue(response.data[0].name);
              this.filters.id = purchase.id;
              this.invoiceFilters.administrativeFileId = this.defaults.values.id;
              this.payChargeFilters.administrativeFileId = this.defaults.values.id;
              this.vehicleSaleFilters.entityId = response.data[0].entity?.id;
              this.alert.info("Expediente registrado con éxito");
              this.mode = "update";
              this.disabled = false;
            }
          },
        error:
          (e) => {
            if (e.status == '401') {
              this.router.navigate(['/login']);
              this.alert.info("Sesión expirada")
              return;
            }
            if (isDevMode)
              this.alert.error(e.message)
            else
              this.alert.error("No ha sido posible dar de alta el expediente");
          }
      });

  }


  showDeletePurchaseAlert(): void {

    this.dialog
      .open(ConfirmDialogComponent, {
        data: `¿Está seguro de querer eliminar este expediente?`
      })
      .afterClosed()
      .subscribe((confirmado: Boolean) => {
        if (confirmado) {
          this.deletePurchase();
        }
      });
  }

  deletePurchase() {
    const purchase = this.form.value;
    purchase.id = this.defaults.values.id;
    this.commonService.delete(this.service, purchase).subscribe({
      next:
        response => {
          if (!response) {
            this.alert.error("No se ha podido borrar el expediente");
          }
          else if (!response.isSuccessful) {
            this.alert.info(response.message);
          }
          else {
            this.alert.info("Expediente eliminado con éxito");
            this.dialogRef.close(purchase);
          }
        },
      error:
        (e) => {
          if (e.status == '401') {
            this.router.navigate(['/login']);
            this.alert.info("Sesión expirada")
            return;
          }
          if (isDevMode)
            this.alert.error(e.message)
          else
            this.alert.error("No se ha podido borrar el expediente");
        }
    });

  }
  modifyPurchase(purchase: Expedient) {

    this.commonService.update(this.service, purchase).subscribe(
      {
        next:
          response => {
            if (!response) {
              this.alert.error("No ha sido posible actualizar el expediente");
            }
            else if (!response.isSuccessful) {
              this.alert.info(response.message);
            }
            else {
              this.defaults.values = response.data[0];
              this.vehicleSaleFilters.entityId = response.data[0].entity?.id;
              this.alert.info("Expediente actualizado con éxito");
            }
          },
        error:
          (e) => {
            if (e.status == '401') {
              this.router.navigate(['/login']);
              this.alert.info("Sesión expirada")
              return;
            }
            if (isDevMode)
              this.alert.error(e.message)
            else
              this.alert.error("No ha sido posible actualizar el expediente");
          }
      });

  }

  showDeleteAdministrativeFileAlert(): void {

    this.dialog
      .open(ConfirmDialogComponent, {
        data: `Se borrarán todos los datos asociados al expediente. ¿Desea continuar?`
      })
      .afterClosed()
      .subscribe((confirmado: Boolean) => {
        if (confirmado) {
          this.deleteAdministrativeFile();
        }
      });
  }

  deleteAdministrativeFile() {
    const invoice = this.form.getRawValue();
    this.commonService.delete(this.service, invoice).subscribe({
      next:
        response => {
          if (!response) {
            this.alert.error("No se ha podido borrar el expediente");
          }
          else if (!response.isSuccessful) {
            this.alert.info(response.message);
          }
          else {
            this.alert.info("Expediente eliminado con éxito");
            this.dialogRef.close(invoice);
          }
        },
      error:
        (e) => {
          if (e.status == '401') {
            this.router.navigate(['/login']);
            this.alert.info("Sesión expirada")
            return;
          }
          if (isDevMode)
            this.alert.error(e.message)
          else
            this.alert.error("No se ha podido borrar el expediente");
        }
    });

  }
  isCreateMode() {
    return this.mode === 'create';
  }

  isUpdateMode() {
    return this.mode === 'update';
  }

  isMenuMode() {
    return this.mode === 'menu';
  }

  onItemSelect(item: any) {
    console.log('onItemSelect', item);
  }
  onSelectAll(items: any) {
    console.log('onSelectAll', items);
  }

  compareObjects(object1: any, object2: any) {
    return object1 && object2 && object1.id == object2.id;
  }
  selectedRole(event: MatSelectChange) {
    this.defaults.role = event.source.triggerValue;
  }

  changePurchaseType(type) {
    this.purchaseType = type.name;
  }

  close() {
    this.dialogRef.close(new Purchase(this.defaults.values));
  }

  onTabChange(event: MatTabChangeEvent) {
    this.currentTabLabel = event.tab.textLabel;

    if (this.currentTabLabel == 'DOCUMENTOS') {
      this.form.get('documentsRemarks').enable();
    } else {
      this.form.get('documentsRemarks').disable();
    }
  }


}
