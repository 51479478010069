import { AfterViewInit, Component, EventEmitter, Inject, Input, isDevMode, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { FormBuilder, UntypedFormControl } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MatSelectChange } from '@angular/material/select';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { IModel } from 'src/app/interfaces/model';
import { ITableParams } from '../models/table-params';
import { ConfirmDialogComponent } from 'src/app/pages/pages/administration/pays-charges/vehicles/confirm-dialog/confirm-dialog.component';
import { CreateUpdateComponent } from '../create-update/create-update.component';
import { Item } from '../models/item';
import { SerieCreateUpdateComponent } from 'src/app/pages/pages/offers/equipments/serie/serie-create-update/serie-create-update.component';
import { MasterService } from 'src/app/services/api/masters.service';
import { MasterCommon } from 'src/app/models/masters/master-common';
import { DecimalPipe, formatNumber } from '@angular/common';
import { EmptyKeyValue } from 'src/app/models/key-value';
import { CommonFilter } from 'src/app/models/masters/common-filter';
import { CommonService } from 'src/app/services/api/common.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { BasicsTransferData } from 'src/app/models/equipments/basics-transfer-data';
import { Serie } from 'src/app/models/equipments/serie';
import { OptionalCreateUpdateComponent } from 'src/app/pages/pages/offers/equipments/optionals/optional-create-update/optional-create-update.component';
import { SaleCreateUpdateComponent } from 'src/app/pages/pages/expedients/sales/sale-create-update/sale-create-update.component';
import { Purchase } from 'src/app/models/expedients/purchase';
import { IFilters } from 'src/app/interfaces/ifilters';
import { TransferData } from 'src/app/models/contacts/transfer-data';
import { PurchaseCreateUpdateComponent } from 'src/app/pages/pages/expedients/purchases/purchase-create-update/purchase-create-update.component';
import { Router } from '@angular/router';

import { Cost } from 'src/app/models/costs/cost';
import { CostCreateUpdateComponent } from 'src/app/pages/pages/administration/costs/cost-create-update/cost-create-update.component';


@UntilDestroy()
@Component({
  selector: 'vex-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'standard'
      } as MatFormFieldDefaultOptions
    }
  ]
})
export class ListComponent<T extends IModel> implements OnInit, AfterViewInit {

  @Output() outputFromChild: EventEmitter<string> = new EventEmitter();
  //outputText : string = "Hi ... message from child";

  console = console;

  layoutCtrl = new UntypedFormControl('fullwidth');

  params: ITableParams<T, T>;
  pending: number;
  total: string;
  settle: number = 0;
  pendingText: string;
  settleText: string;
  allSelected: boolean;

  visible: boolean = true;



  dataSource: MatTableDataSource<T> = new MatTableDataSource();



  subject$: ReplaySubject<T[]> = new ReplaySubject<T[]>(1);
  data$: Observable<T[]> = this.subject$.asObservable();
  items: T[] = [];


  pageSize = 5;
  pageIndex = 0;
  order = "asc";
  orderType = "";
  pageSizeOptions: number[] = [5, 10, 20, 50];

  selection = new SelectionModel<T>(true, []);
  searchCtrl = new UntypedFormControl();



  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  onAdd: EventEmitter<string> = new EventEmitter();

  constructor(@Inject(MAT_DIALOG_DATA) protected defaults: any,
    private _decimalPipe: DecimalPipe,
    private router: Router,
    private alert: ToastService,
    private dialog: MatDialog,
    private masterService: MasterService<IModel>,
    private commonService: CommonService<T, IFilters>,
    private dialogRef: MatDialogRef<ListComponent<T>>) {
  }

  get visibleColumns() {
    return this.params.columns.filter(column => column.visible).map(column => column.property);
  }

  sendDataToParent(data: any) {
    this.onAdd.emit(data);
    this.params.filters.orderTypeId = data.id;
    this.loadData();
  }

  async getData() {
    if (this.params.pageSize)
        this.pageSize = this.params.pageSize;
    if (this.params.tableData)
      return of(this.params.tableData);
    else (this.params.service)
    if (this.params.filters)
      return of(await this.masterService.getAllFilteredAsync(this.params.service, this.params.filters) as unknown as Array<T>);
    else
      return of(await this.masterService.get(this.params.service) as unknown as Array<T>);

  }
  loadFilters() {
    this.params.filters.pageNumber = this.pageIndex + 1;
    if(this.params?.paginatedOnBack)
      this.params.filters.rowsPerPage =  this.pageSize;
    else
      this.pageSize = this.params.filters.rowsPerPage;

    this.params.filters.order = this.order;
    this.params.filters.orderBy = this.orderType;
  }


  async loadPaginatedData() {
    this.loadFilters();
    this.commonService.getAll(this.params.service, this.params.filters as CommonFilter).subscribe({
      next:
        response => {
          if (!response) {
            this.alert.error("No hay " + this.params.title);
          }
          else if (!response.isSuccessful) {
            this.alert.info(response.message);
          }
          else {
            this.items = response.data;
            this.paginator.length = response.total;
            //this.paginator.pageIndex = this.pageIndex;
            this.subsribeData();
          }
        },
      error:
        (e) => {
          if (e.status == '401') {
            this.router.navigate(['/login']);
            this.alert.info("Sesión expirada")
            return;
          }
          if (isDevMode)
            this.alert.error(e.message)
          else
            this.alert.error("No ha sido posible recuperar la lista de " + this.params.title);
        }
    });
    return [];
  }

  ngOnChanges() {
    console.log(this.params.list.tableData);
  }
  ngOnInit() {    
    this.params = this.defaults;
    if (this.params?.filters?.name)
      this.params.filters.name = "";
    let total: number = this.params.extraValue as unknown as number;
    /*     if(total < 0)
          this.pending = 0;
        else */
    this.pending = this.params.extraValue as unknown as number * 1;
    if(this.params?.filters?.rowsPerPage && this.params?.paginatedOnBack)
      this.pageSize = this.params?.filters?.rowsPerPage;

    /* if (this.params.title === 'Cobros' || this.params.title === 'Costes') {
      if (total && total < 0)
        total = total * -1;
      if (this.pending && this.pending < 0)
        this.pending = this.pending * -1;
    } */
    this.total = formatNumber(total, 'es-ES', '1.0-2');
    this.allSelected = this.params.allSelected;
    this.settleText = formatNumber(this.settle || 0, 'es-ES', '1.0-2');
    this.pendingText = formatNumber(this.pending, 'es-ES', '1.0-2');

  /*   this.dataSource.filterPredicate = (entry: T, filter: string) => {
     return entry.expedientes?.filter(filter);
    } */

  /*   this.dataSource.filterPredicate = (entry: T, filter: string) =>
    entry.expedientes.includes(filter);
 */
  }

  nestedFilterCheck(search, data, key) {
    console.log(data[key]);
    if (typeof data[key] === 'object') {
      for (const k in data[key]) {
        if (data[key][k] !== null) {
          search = this.nestedFilterCheck(search, data[key], k);
        }
      }
    } else {
      search += data[key];
    }
    return search;
  }
  getPaginatedData() {

    if (this.items !== undefined)
      return of(this.items);
    else
      return of([])

  }
  subsribeData() {
    this.getPaginatedData().subscribe(items => {
      this.subject$.next(items);
    });

    this.data$.pipe(
      filter<T[]>(Boolean)
    ).subscribe(items => {
      this.items = items;
      this.dataSource.data = items;
    });

    

  }

  ngAfterContentInit() {

    this.loadData();

  }

  async loadData() {
    if (this.params.paginatedOnBack) {
      this.loadPaginatedData();
    }
    else {
      (await this.getData()).subscribe(items => {
        this.subject$.next(items);
      });

      this.dataSource = new MatTableDataSource();
      //this.dataSource.filterPredicate = this.createFilter();

    /*   this.dataSource.filterPredicate = (entry: T, filter: string) =>
      entry.expedientes.includes(filter); */
      //this.dataSource = new MatTableDataSource(this.tableData);
      this.data$.pipe(
        filter<T[]>(Boolean)
      ).subscribe(items => {
        this.items = items;
        this.dataSource.data = items;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortingDataAccessor = (obj: any, property: string) => property.split('.').reduce((o, p) => o && o[p], obj);
        this.dataSource.sort = this.sort;
        if (this.allSelected)
          this.dataSource.data.forEach(row => this.selection.select(row));
      });

    /*   this.dataSource.filterPredicate = (entry: T, filter: string) =>
      entry.expedientes.includes(filter);
 */
      this.searchCtrl.valueChanges.pipe(
      ).subscribe(value => this.onFilterChange(value));

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

    }

  }

  createFilter(): (data: T, filter: string) => boolean {
    let filterFunction = function (data, filter) :boolean {
      return data.expedientes.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    }
    return filterFunction;
  }
  ngAfterViewInit() {
    /*  this.dataSource.paginator = this.paginator;
     this.dataSource.sort = this.sort; */
  }


  /*  addItems() {
     const customer = this.form.value;
 
     if (!customer.imageSrc) {
       customer.imageSrc = 'assets/img/avatars/1.jpg';
     }
 
     this.dialogRef.close(customer);
   } */



  /* addItem(item: T) {

    const item = this.form.value;
    item.id = this.defaults.values.id;

    
  } */

  addItems(items: T[]) {
    if (this.params.service === 'proformas/vehicles') {
      this.dialog.open(ConfirmDialogComponent,
        {
          data: items
        }).afterClosed()
        .subscribe(result => {
          if (result.selectedItem) {

            this.applyCast(result, items);

          }
        });
    }
    else if(this.params.service === 'proformas/invoices/preview'){
      if(this.params?.filters?.shipmentCostType?.id === 2)
        items.forEach(i=> {
          if(i.vehicleCostType.id == 1)
            items.push(...this.items.filter(x=> x.vehicle.id == i.vehicle.id && x.vehicleCostType?.id == 2))
        })
        
      this.dialogRef.close(items);
    }
    else {
      //items.forEach(c => this.addItem(c));
      //this.params.tableData = items;
      if (this.params.title === 'Básicos')
        items.forEach(c => this.addEmptyBasic(c));
      this.dialogRef.close(items);
    }
  }

  fillPurchases(items, total, selectedItem) {
    let result: Array<T> = [];
    let price: number = total / items.length;
    let lowerItems: Array<T> = items.filter(c => c.amountSettPendiente < price);
    if (lowerItems?.length > 0) {
      //items.forEach(c => (c.amountPay = c.purchasePrice, c.amountPending = 0, c.priceAssignmentType = new CommonFilter(selectedItem)));
      lowerItems.forEach(c => {
        if (c.amountSettPendiente > price)
          c.amountPay = price;
        else
          c.amountPay = c.amountSettPendiente;
        c.amountSettPendiente = 0;
        c.priceAssignmentType = new CommonFilter(selectedItem);
        items.splice(items.findIndex(item => item.id === c.id), 1);
        result.push(c);
      });
      const sum = lowerItems.map(e => e.amountPay).reduce((sum, value) => Number(sum) + Number(value), 0);

      //items = items.filter(lowerItems); // sin los asignados
      total = total - sum; // sin los asignados

      result.push(...this.fillPurchases(items, total, selectedItem));
    }
    else {
      //lowerItems = null;
      //items.forEach(c => (c.amountPay = price, c.amountPending = 0, c.priceAssignmentType = new CommonFilter(selectedItem)));
      result.push(...items);
      items.forEach(c => {
        if (c.amountSettPendiente > price)
          c.amountPay = price;
        else
          c.amountPay = c.amountSettPendiente;
        c.amountSettPendiente = 0;
        c.priceAssignmentType = new CommonFilter(selectedItem);
      });
    }

    return result;
  }
  fillSales(items, total, selectedItem) {
    let result: Array<T> = [];
    let price: number = total / items.length;
    let lowerItems: Array<T> = items.filter(c => c.amountSettPendiente < price);
    if (lowerItems?.length > 0) {
      //items.forEach(c => (c.amountPay = c.salePrice, c.amountPending = 0, c.priceAssignmentType = new CommonFilter(selectedItem)));
      lowerItems.forEach(c => {
        if (c.amountSettPendiente > price)
          c.amountPay = price;
        else
          c.amountPay = c.amountSettPendiente;
        c.amountSettPendiente = 0;
        c.priceAssignmentType = new CommonFilter(selectedItem);
        items.splice(items.findIndex(item => item.id === c.id), 1);
        result.push(c);
      });
      const sum = lowerItems.map(e => e.amountPay).reduce((sum, value) => Number(sum) + Number(value), 0);
      //items = items.filter(lowerItems); // sin los asignados
      total = total - sum; // sin los asignados

      result.push(...this.fillSales(items, total, selectedItem));
    }
    else {
      //lowerItems = null;
      result.push(...items);
      items.forEach(c => {
        if (c.amountSettPendiente > price)
          c.amountPay = price;
        else
          c.amountPay = c.amountSettPendiente;
        c.amountSettPendiente = 0;
        c.priceAssignmentType = new CommonFilter(selectedItem);
      });
    }


    return result;
  }

  addEmptyBasic(basic) {
    basic.equipmentPack = new EmptyKeyValue();
    return basic;
  }
  applyCast(result: any, items: T[]) {
    let price: number;
    let totalSum: number;
    /*  if (this.params.filters.sign == -1)
       totalSum = items.map(e => e.purchasePrice).reduce((sum, value) => Number(sum) + Number(value), 0);
     else
       totalSum = items.map(e => e.salePrice).reduce((sum, value) => Number(sum) + Number(value), 0);
  */
    totalSum = items.map(e => e.amountSettPendiente).reduce((sum, value) => Number(sum) + Number(value), 0);
    let total: number = this.params.extraValue as unknown as number;
    if (result.selectedItem === '1') {

      if (totalSum <= total) {
        /*  if (this.params.filters.sign == -1)
           items.forEach(c => (c.amountPay = c.purchasePrice, c.amountPending = 0, c.priceAssignmentType = new CommonFilter(result.selectedItem)));
         else
           items.forEach(c => (c.amountPay = c.salePrice, c.amountPending = 0, c.priceAssignmentType = new CommonFilter(result.selectedItem))); */
        items.forEach(c => {
          if (c.amountSettPendiente > price)
            c.amountPay = price;
          else
            c.amountPay = c.amountSettPendiente;
          c.amountSettPendiente = 0;
          c.priceAssignmentType = new CommonFilter(result.selectedItem);

        });
      }
      else {

        if (this.params.filters.sign == -1)
          items = this.fillPurchases(items, total, result.selectedItem);
        else
          items = this.fillSales(items, total, result.selectedItem);

      }

    }
    else if (result.selectedItem === '2') {
      let sum: number;
      let percentage = 100 / result.percentage;
      /*  if (this.params.filters.sign == -1)
         sum = items.map(e => e.purchasePrice / percentage).reduce((sum, value) => Number(sum) + Number(value), 0);
       else
         sum = items.map(e => e.salePrice / percentage).reduce((sum, value) => Number(sum) + Number(value), 0); */
      sum = items.map(e => e.amountSettPendiente / percentage).reduce((sum, value) => Number(sum) + Number(value), 0);

      if (sum > total) {
        this.alert.info("No se puede asignar ese porcentaje, los importes excederían del total");
        return;
      }
      else {
        /*  if (this.params.filters.sign == -1) {
           items.forEach(c => {
             c.amountPay = c.purchasePrice / percentage;
             c.amountPending = c.purchasePrice - c.amountPay;
             c.priceAssignmentType = new CommonFilter(result.selectedItem);
           })
         }
         else {
           items.forEach(c => {
             c.amountPay = c.salePrice / percentage;
             c.amountPending = c.salePrice - c.amountPay;
             c.priceAssignmentType = new CommonFilter(result.selectedItem);
           })
         } */
        items.forEach(c => {
          c.amountPay = c.amountSettPendiente / percentage;
          c.amountSettPendiente = c.amountSettPendiente - c.amountPay;
          c.priceAssignmentType = new CommonFilter(result.selectedItem);
        })
      }
    }
    if (result.selectedItem === '3') {
      let i: number = total;
      let sum: number;

      sum = items.map(e => e.amountSettPendiente).reduce((sum, value) => Number(sum) + Number(value), 0);

      /* if (sum > total) {
        this.alert.info("El total de importes supera el total del pago/cobro");
        return;
      }
      else { */
      let deleteItems = [];
      items.forEach(c => {
        if (i !== 0) {
          /* if (this.params.filters.sign == -1) {
            if (c.purchasePrice < i) {
              c.amountPay = c.purchasePrice;
              c.amountPending = 0;
              c.priceAssignmentType = new CommonFilter(result.selectedItem);
              i = i - c.purchasePrice;
            }
            else {
              c.amountPay = i;
              c.amountPending = c.purchasePrice - i,
                c.priceAssignmentType = new CommonFilter(result.selectedItem);
              i = 0;
            }
          }
          else {
            if (i < c.salePrice) {
              c.amountPay = c.salePrice;
              c.amountPending = 0;
              c.priceAssignmentType = new CommonFilter(result.selectedItem);
              i = i - c.salePrice;
            }
            else {
              c.amountPay = i;
              c.amountPending = c.salePrice - i,
                c.priceAssignmentType = new CommonFilter(result.selectedItem);
              i = 0;
            }
          } */
          if (c.amountSettPendiente < i) {
            c.amountPay = c.amountSettPendiente;
            
            c.priceAssignmentType = new CommonFilter(result.selectedItem);
            i = i - c.amountSettPendiente;
            c.amountSettPendiente = 0;
          }
          else {
            c.amountPay = i;
            c.amountSettPendiente = c.amountSettPendiente - i,
              c.priceAssignmentType = new CommonFilter(result.selectedItem);
            i = 0;
          }
        }
        else
          deleteItems.push(c);
          
      })
      deleteItems.forEach(c => {
        items.splice(items.indexOf(c), 1);
      });
      //}
    }
   

    this.dialogRef.close(items);
  }
  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
   

    this.dataSource.filter = value;
   
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  isAllSelected() {
    let numSelected = this.selection.selected.length;
    let initIndex: number = 0;
    let total = this.pageSize;
    let page = this.dataSource['_renderData']['_value'].length;
    for (let index = initIndex; index < total; index++) {
      if (this.dataSource.data[index]) {
        if (this.params.service == 'proformas/vehicles' && this.dataSource.data[index]['amountSettPendiente'] == 0)
          numSelected++;
        else if (this.dataSource.data[index].amount && (this.dataSource.data[index].amount - this.dataSource.data[index].amountSett) == 0 && this.dataSource.data[index].amountSettLoaded == undefined)
          numSelected++;
        else if (this.dataSource.data[index].amount && (this.dataSource.data[index].amount - this.dataSource.data[index].settledAmount) == 0 && this.dataSource.data[index].amountSettLoaded == undefined)
          numSelected++;
      }
    }
    return numSelected === total; //page;
  }
  masterToggle() {
    this.isAllSelected() ?
      this.unselectRows() :
      this.selectRows();
  }
  unselectRows() {
    let total = this.dataSource.data.length;//this.pageSize;
    let initIndex: number = 0;
    if (this.pageIndex > 0) { // && this.dataSource['_renderData']['_value'].length !== this.dataSource.data.length) {
      initIndex = (this.pageIndex) * this.pageSize;
      total = this.pageSize * (this.pageIndex + 1);
    }
    for (let index = initIndex; index < total; index++) {
      if (this.dataSource.data[index]) {
        if (this.params.service == 'proformas/vehicles' && this.dataSource.data[index]['amountSettPendiente'] == 0)
          continue;
        else if (this.dataSource.data[index].amount && (this.dataSource.data[index].amount - this.dataSource.data[index].amountSett) == 0 && this.dataSource.data[index].amountSettLoaded == undefined)
          continue;
        else if (this.dataSource.data[index].amount && (this.dataSource.data[index].amount - this.dataSource.data[index].settledAmount) == 0 && this.dataSource.data[index].amountSettLoaded == undefined)
          continue;
        
          this.selection.deselect(this.dataSource.data[index]);
        this.checkSettled(this.dataSource.data[index])
      }
    }
  }

  selectRows() {
    let total = this.dataSource.data.length;//this.pageSize;
    let initIndex: number = 0;
    if (this.pageIndex > 0) { // && this.dataSource['_renderData']['_value'].length !== this.dataSource.data.length) {
      initIndex = (this.pageIndex) * this.pageSize;
      total = this.pageSize * (this.pageIndex + 1);
    }
    for (let index = initIndex; index < total; index++) {
      if (this.dataSource.data[index]) {
        if (this.params.service == 'proformas/vehicles' && this.dataSource.data[index]['amountSettPendiente'] == 0)
          continue;
        else if (this.dataSource.data[index].amount && (this.dataSource.data[index].amount - this.dataSource.data[index].amountSett) == 0 && this.dataSource.data[index].amountSettLoaded == undefined)
          continue;
        else if (this.dataSource.data[index].amount && (this.dataSource.data[index].amount - this.dataSource.data[index].settledAmount) == 0 && this.dataSource.data[index].amountSettLoaded == undefined)
          continue;
        this.selection.select(this.dataSource.data[index]);

        this.checkSettled(this.dataSource.data[index])
      }
    }
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  onLabelChange(change: MatSelectChange, row: T) {
    const index = this.items.findIndex(c => c === row);
    //this.articles[index].labels = change.value;
    this.subject$.next(this.items);
  }

  mouseLeave(trigger) {
    trigger.closeMenu();
  }

  closeMenu(trigger) {
    trigger.closeMenu();

  }
  close() {
    this.dialogRef.close();
  }
  selectFile(row: any) {
    if (this.params.service == 'proformas/vehicles' && row['amountSettPendiente'] == 0)
      return;
    else if (row.amount && (row.amount - row.amountSett) == 0 && row.amountSettLoaded == undefined)
      return;
    else if (row.amount && (row.amount - row.settledAmount) == 0 && row.amountSettLoaded == undefined)
      return;
    else {
      if (this.params.isAdminFile) {
        this.loadAdminFile(row.id)
      }
      else if (this.checkTransport(row)) {
        if (this.params.uniqueSelect)
          this.unselectRows()
        this.selection.toggle(row);
        this.checkSettled(row);
      }
    }

  }

  loadAdminFile(id) {
    this.params.filters.id = id;
    this.commonService.getAll(this.params.service, this.params.filters).subscribe({
      next:
        response => {
          if (!response) {
            this.alert.error("Expediente no encontrado");
          }
          else if (!response.isSuccessful) {
            this.alert.info(response.message);
          }
          else {
            const sales = response.data.map(x => new Purchase(x));
            sales[0].linkedAdminFiles = null;
            if (this.params.filters.orderTypeId == 1) {
              this.dialog.open(SaleCreateUpdateComponent, {
                data: new TransferData(
                  {
                    values: sales[0],
                    type: this.params.extraValue,
                    filters: this.params.filters
                  })
              });
            }
            else {
              this.dialog.open(PurchaseCreateUpdateComponent, {
                data: new TransferData(
                  {
                    values: sales[0],
                    type: this.params.extraValue,
                    filters: this.params.filters
                  })
              });
            }
          }
        },
      error:
        (e) => {
          if (e.status == '401') {
            this.router.navigate(['/login']);
            this.alert.info("Sesión expirada")
            return;
          }
          if (isDevMode)
            this.alert.error(e.message)
          else
            this.alert.error("No ha sido posible recuperar el expediente");
        }
    });
  }
  checkSettled(row) {
    if (this.params.showAmount) {
      let p = this.selection.isSelected(row);
      const amountSett = row.amountSett || row.settledAmount || row.cost || 0;
      let amount = (row.amount * 1) - (amountSett * 1);
      if (p) {
        if (amount > 0) {
          if ((this.pending - (amount * 1)) <= 0) {
            row.amountSettLoaded = amountSett;
            //row.amountSett =  this._decimalPipe.transform((Number(this.pending) + Number(amountSett)) - row.amountSettLoaded, '1.2-2').replace(',', '');
            row.amountSett =  (Number(this.pending) + Number(amountSett));
            row.settledAmount = Number(this.pending) + Number(amountSett);
            /* row.amountSett = Number(amount)  + Number(amountSett);
            row.settledAmount = Number(amount) + Number(amountSett); */
            this.settle = Number(this.pending) + Number(this.settle);
            this.pending = 0;
            this.visible = false;
          }
          else {
            row.amountSettLoaded = amountSett;
            this.pending -= amount;
            //row.amountSett =  this._decimalPipe.transform((Number(amount) + Number(amountSett)) - row.amountSettLoaded, '1.2-2').replace(',', '');
            row.amountSett =  (Number(amount) + Number(amountSett));
            row.settledAmount = Number(amount) + Number(amountSett);
            this.settle = this.settle + (amount * 1);
            //row.amount = 0;
          }
        }
      }
      else {
        this.settle -= (amountSett - row.amountSettLoaded || 0) * 1;
        this.pending += (amountSett - row.amountSettLoaded || 0) * 1;
        //row.amountSett =  this._decimalPipe.transform((row.amountSettLoaded || 0) - row.amountSettLoaded, '1.2-2').replace(',', '');
        row.amountSett =  (row.amountSettLoaded || 0);
        row.settledAmount = row.amountSettLoaded || 0;
        this.visible = true;
        /* 
        this.pending += (row.amount - row.amountSettLoaded || 0) * 1;
        this.settle -= (row.amount - row.amountSettLoaded || 0) * 1;
        row.amountSett = row.amountSettLoaded || 0;
        row.settledAmount = row.amountSettLoaded || 0;
        this.visible = true; */
      }
      this.settleText = formatNumber(this.settle || 0, 'es-ES', '1.0-2');
      this.pendingText = formatNumber(this.pending || 0, 'es-ES', '1.0-2');
      row.amountSettLoaded = amountSett;
    }
  }

  getVisible(row) {
    if (this.params.service == 'proformas/vehicles' && row['amountSettPendiente'] == 0) {
      return false;
    }
    else if (row.amount && (row.amount - row.amountSett) == 0 && row.amountSettLoaded == undefined)
      return false;
    else if (row.amount && (row.amount - row.settledAmount) == 0 && row.amountSettLoaded == undefined)
      return false;
    else {
      if (!this.visible) {
        if (!this.selection.isSelected(row))
          return false;
      }
    }
    return true;

  }

  checkTransport(row) {
    if (this.params.service == 'proformas/invoices/preview' && row.vehicleCostType.id == 2 && this.params?.filters?.shipmentCostType?.id == 2) {
      return false;
    }
    return true;

  }
  async createItem() {
    if (this.params.service == "accountingentries/costs") {
      this.dialog.open(CostCreateUpdateComponent, {
        data: new TransferData(
        {
          type: "Coste",
          isMenu: true,
          readOnly: true
          
        })
      }).afterClosed().subscribe((cost) => {
  
       if (cost?.id) {
          this.items.unshift(cost);
          this.subject$.next(this.items);
        }
         /* else */
          //this.loadData();
      });
    }
    else if (this.params.service == "standardEquipments") {
      this.dialog.open(SerieCreateUpdateComponent, {
        data: new BasicsTransferData(
          {
            type: null,
            categories: await this.masterService.get("equipmentClass") as unknown as Array<MasterCommon>,
            packs: []
          })
      }).afterClosed().subscribe((serie: Serie) => {
        if (serie) {
          this.items.unshift(serie as unknown as T);
          this.subject$.next(this.items);
          this.dataSource.data.forEach(row => this.selection.select(serie as unknown as T));
        }
      });
    }
    else if (this.params.service == "optionalEquipments") {
      this.dialog.open(OptionalCreateUpdateComponent, {
        data: new BasicsTransferData(
          {
            type: null,
            categories: await this.masterService.get("equipmentClass") as unknown as Array<MasterCommon>,
            packs: []
          })
      }).afterClosed().subscribe((optional: Optional) => {
        if (optional) {

          this.items.unshift(optional as T);
          this.subject$.next(this.items);
          this.dataSource.data.forEach(row => this.selection.select(optional as T));
        }
      });
    }
    else {
      this.dialog.open(CreateUpdateComponent,
        {
          data: new Item(
            {
              element: this.params.element,
              hasImage: this.params.hasImage,
              fields: this.params.columns,
              titleProperty: this.params.titleProperty,
              extraValue: this.params.extraValue,
              service: this.params.service,
              linkService: this.params.linkService,
              filters: this.params.filters,
            })
        }).afterClosed().subscribe((item: T) => {

          if (item) {
            if (this.params.linkService) {
              this.dialogRef.close(item);
            }
            else {
              this.items.unshift(item);
              this.subject$.next(this.items);
              this.dataSource.data.forEach(row => this.selection.select(item));
            }
          }
        });
    }

  }

  onPaginateChange(event) {
    //this.selection.clear();
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    if (this.params.paginatedOnBack) {
      this.loadData()
    };
    const top = document.getElementById('top');
    top.scrollIntoView();


  }
  sortData(event) {
    if (this.params.paginatedOnBack) {
      this.orderType = event.active;
      this.order = event.direction;
      this.loadData();
    }
  }

  getColor(amount) {
    if (amount < 0)
      return 'red'
    else
      return ''

  }
  getColorAmount() {

    if (this.pending === 0)
      return 'green';
    else if (this.pending < 0)
      return 'red';
    else
      return '';


  }

}